import './index.css';

const KCSS = () => {

    return <div className={'css_block'}>
        <div className={'css_left'}>
            <div>
                <div style={{marginTop: '100px', width: '90%', height: '100%'}}>
                <span style={{
                    fontSize: '30px'
                }}>低代码，也能</span>
                    <span style={{
                        fontSize: '50px',
                        color: '#3757F9'
                    }}>深度定制</span>
                    <span style={{
                        fontSize: '30px'
                    }}>每个样式</span>
                </div>
                <p/>
            </div>
            <div>
                <img width={'600px'} src={'./img/css2.png'} style={{
                    float: 'left',
                    marginLeft: '20px',
                    width: '300px'
                }}/>
            </div>
        </div>
        <div className={'css_right'}>
            <img src={'./img/css.png'} width={'300px'}/>
        </div>
    </div>

}


export default KCSS;