import './index.css';

const Page = () => {

    return <div className={'ui_block'}>
        <div className={'ui_right'}>
            <img src={'./img/3step.gif'} width={'650px'}/>
        </div>
        <div className={'ui_left'}>
            <div style={{marginTop: '70px', width: '100%'}}>
                <span style={{
                    fontSize: '50px',
                    color: '#3757F9'
                }}>本地</span>
                <span style={{
                    fontSize: '30px'
                }}>测试|多端运行</span>
            </div>
            <p/>
            <span>运行环境支持linux\windows\macos</span>
        </div>
    </div>
}

export default Page;