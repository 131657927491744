import './index.css';

const Flow = () => {

    return <div className={'flow_block'}>
        <div className={'flow_left'}>
            <div style={{marginTop: '100px', width: '100%'}}>
                <span style={{
                    fontSize: '50px',
                    color: '#3757F9'
                }}>定制</span>
                    <span style={{
                        fontSize: '30px'
                    }}>工作流|开放api</span>
            </div>
            <p/>
            <span style={{
                wordBreak:'normal',
                width:'10px',
                wordWrap:'break-word',
                whiteSpace:'pre-wrap',
                display:'inline',
                height:'50px',
            }}>low-code与pro-code完美结合</span>
        </div>
        <div className={'flow_right'}>
            <img  src={'./img/flow.gif'} width={'650px'} style={{marginLeft:'20px'}}/>
        </div>
    </div>

}

export default Flow;