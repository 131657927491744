import './announcement.css';


const Announcement = () => {
    return (
        <div className={'announcement'}>
            <h3>交流</h3>
            <span>官方邮箱:kusuo_lowcode@163.com</span>
        </div>
    )
}

export default Announcement;