import './index.css';

const Data = () => {

    return <div className={'data_block'}>
        <div className={'data_right'}>
            <img width={'600px'} src={'./img/linux.gif'}/>
        </div>
        <div className={'data_left'}>
            <div style={{marginTop: '100px', width: '90%', height: '100%'}}>
                <span style={{
                    fontSize: '30px'
                }}>私有化部署</span>
                <span style={{
                    fontSize: '50px',
                    color: '#3757F9'
                }}>一条命令</span>
            </div>
            <p/>
            <span>轻松调整,就能定制私有ERP等管理系统</span>
        </div>
    </div>

}

export default Data;