import './home.css'
import {Card, Tooltip} from "antd";
import tpl_list from "./tpls";
import base64_files from "./base64";
import {DownloadOutlined} from '@ant-design/icons';
import Title from "./block/title";
import UI from "./block/ui";
import Page from "./block/page";
import Flow from "./block/flow";
import Data from "./block/data";
import KCSS from "./block/kcss";

function Home() {


    return (
        <div>
            <div className={'home_bg'}>
                <Title/>
            </div>
            <div className={'home_default'}>
                <UI/>
            </div>

            <div className={'home_default'}>
                <Page/>
            </div>

            <div className={'home_default'}>
                <Flow/>
            </div>

            <div className={'home_default'}>
                <Data/>
            </div>

            <div className={'home_default'}>
                <KCSS/>
            </div>

            <div className={'home_message'}>
                <div className={'home_message_content'}>
                    <h3>联系我们</h3>
                    <span>kusuo_lowcode@163.com</span>
                </div>
                <div className={'home_message_content'}>
                    <h3>备案号</h3>
                    <span>浙ICP备2023017510号</span>
                </div>
            </div>


        </div>
    );
}

export default Home;
