import './index.css';

const UI = () => {

    return <div className={'ui_block'}>
        <div className={'ui_left'}>
            <div style={{marginTop: '100px', width: '100%'}}>
                <span style={{
                    fontSize: '50px',
                    color: '#3757F9'
                }}>加速</span>
                    <span style={{
                        fontSize: '30px'
                    }}>定制应用程序</span>
            </div>
            <p/>
            <span>轻松调整,就能定制私有ERP等管理系统</span>
        </div>
        <div>

        </div>
        <div className={'ui_right'}>
            <img className={'img_huxi'} src={'./img/plugin.png'}/>
            <img className={'img_huxi2'} src={'./img/snip.png'}/>
            <img className={'img_huxi3'} src={'./img/data.png'}/>
        </div>
    </div>

}

export default UI;