import './index.css';
import {Tooltip} from "antd";
import {Button, ButtonProps, styled} from '@mui/material';
import {purple} from "@mui/material/colors";


const Title = () => {
    const jump = (v: string) => {
        fetch('./conf.json').then(data => data.json()).then(x => {
            console.log('res >>', x)
            if (!x || !x.version) {
                return
            }
            switch (v) {
                case 'win':
                    window.location.href = `kusuo-lowcode-${x.version}-Setup.exe`;
                    break;
                case 'mac_x86':
                    window.location.href = `kusuo-lowcode-${x.version}.dmg`;
                    break;
                case 'mac_arm64':
                    window.location.href = `kusuo-lowcode-${x.version}-arm64.dmg`;
                    break
                case 'linux':
                    window.location.href = `kusuo_runner_linux_${x.version}`
            }
        })
    }

    const installTooltip = `mac 安装说明——如安装后无法启动,安装后在终端执行:
    sudo spctl --master-disable && 
    sudo xattr -r -d com.apple.quarantine  /Applications/kusuo-lowcode.app
    `

    const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
        color: 'white',
        backgroundColor: '#503BF9',
        float: 'left',
        marginRight: '20px',
        marginTop: '10px',
        minWidth:'250px',
        '&:hover': {
            backgroundColor: '#2F238E',
        },
    }));


    return <div className={'home_block'}>
        <img alt='' src={'./img/kusuo-icon.png'} className={'home_icon'}/>
        <p/>
        <div style={{
            fontSize: '50px'
        }}>kusuo —— 高性能低代码开发IDE
        </div>
        <p/>
        <div>双击运行，私有化部署，可视化快速搭建网页、H5页面。助力企业信息化
        </div>
        <p/>
        <Tooltip style={{whiteSpace: 'pre-wrap'}} title={installTooltip} placement="right">
            <ColorButton className={'title_button'} variant="outlined" onClick={() => {
                jump('mac_x86')
            }}>Mac版下载(intel测试版)
            </ColorButton>
        </Tooltip>
        <Tooltip title={installTooltip} placement="right">
            <ColorButton onClick={() => {
                jump('mac_arm64')
            }}>Mac版下载(arm64测试版)
            </ColorButton>
        </Tooltip>
        <Tooltip title={''} placement="right">
            <ColorButton
                onClick={() => {
                    jump('win')
                }}>windows版下载(测试版)
            </ColorButton>
        </Tooltip>
        <Tooltip title={'执行:./kusuo_runner_linux app.zip'} placement="right">
            <ColorButton
                onClick={() => {
                    jump('linux')
                }}>linux版下载(测试版)
            </ColorButton>
        </Tooltip>
    </div>

}

export default Title;