import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Home from './windows/home';
import Announcement from "./windows/announcement";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/announcement/:p1" element={(<Announcement />)} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
